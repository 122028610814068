@import '~@react-md/theme/dist/color-palette';

$rmd-theme-primary: $rmd-red-800;
$rmd-theme-secondary: $rmd-blue-900;
$rmd-theme-light: true;

$rmd-theme-dark-elevation: 'prefers-color-scheme';
$rmd-utils-auto-dense: true;

//Alteração no menu lateral para fixar o mesmo no topo e evitar que ele role junto com a barra de rolagem
.rmd-layout-navigation--header-offset {
  top: 0 !important;
}
.rmd-layout-navigation--mini {
  top: 3.5rem !important;
  position: absolute !important;
}

//Alteração na cor da borda dos inputs
$rmd-text-field-active-color: $rmd-theme-primary !default;

//Alteração na fonte dos inputs
$rmd-label-font-size: 0.75em !default;
$rmd-label-floating-padding: 0rem !default;
$rmd-form-message-margin-bottom: 0rem !default;

textarea,
input[type] {
  font-size: 0.75em !important;
}

//Alteração na fonte dos selects
.rmd-select__value {
  font-size: 0.75em !important;
}

//Alteração no espaçamento dos grids e células
$rmd-grid-cell-margin: 0 !default;

.rmd-grid {
  padding: 0.5rem !important;
}

//Alteração na borda dos botões
$rmd-button-text-border-radius: 0.25rem !default;

//Alteração na cor ativa e alinhamento do switch
$rmd-form-active-color: var(--rmd-theme-primary) !default;
$rmd-switch-container-vertical-padding: 2.2rem !default;
$rmd-switch-container-horizontal-padding: 0rem !default;

//Alteração no padding da mensagem de erro
$rmd-form-message-margin-bottom: 0.25rem !default;
$rmd-form-message-min-height: 0.25rem !default;
$rmd-text-field-outline-padding: 0 !default;

//Alteração na cor do componente de loading
$rmd-progress-color: $rmd-theme-secondary !default;
$rmd-progress-background-color: rmd-theme-get-swatch(
  $rmd-theme-primary,
  300,
  false,
  rgba($rmd-theme-primary, 1),
  rmd-progress-background-color
) !default;

//Alteração na margem esquerda dos sub menus no  menu lateral
$rmd-tree-item-padding-base: 1.5rem !default;

//Alteração no espaço entre o ícone o texto no menu lateral
$rmd-list-item-text-keyline: 3rem !default;

@import 'react-md/dist/styles';

@media (prefers-color-scheme: dark) {
  :root {
    @include rmd-theme-dark;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    @include rmd-theme-light;
  }
}

.dark-theme {
  @include rmd-theme-dark;
}

.light-theme {
  @include rmd-theme-light;
}
